<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Test Orders</h3>
   <div>
      <button class="btn btn-primary btn-icon" (click)="displayExport=true"> Export</button>
   </div>
</div>
<p-dialog header="Export Data" [(visible)]="displayExport" modal="true" [closable]>

   <div style="height: 400px;">
      <form [formGroup]="exportForm">
         <div class="row">
            <div class="col-6">
               <label for="">From</label>
               <app-date-picker formControlName="from"></app-date-picker>
            </div>
            <div class="col-6">
               <label for="">To</label>
               <app-date-picker formControlName="to"></app-date-picker>
            </div>
            <div class="col-sm-12">
               <div class="form-group">
                  <label for="">Batch Code</label>
                  <p-autoComplete field="name" formControlName="batch" [suggestions]="batchResults"
                                  (completeMethod)="searchBatch($event)">
                     <ng-template let-brand pTemplate="item">
                        <div>{{ brand.name }}</div>
                        <small>{{ brand.batch_code }}</small>
                     </ng-template>

                  </p-autoComplete>
               </div>
            </div>
            <div class="col-12">
               <div class="form-group">
                  <label for="">Status</label>
                  <select class="form-control" formControlName="status">
                     <option value="">All</option>
                     <option value="paid">Paid</option>
                     <option value="partial">Partial</option>
                     <option value="pending">Pending</option>
                  </select>
               </div>
            </div>
         </div>
      </form>

   </div>
   <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" label="Export" (click)="export()"></p-button>
   </ng-template>
</p-dialog>
<label for="">Select Batch</label>
<div class="d-flex">
   <p-autoComplete
      [(ngModel)]="batchName"
      (onSelect)="filterByBatch($event)"
      [forceSelection]="true" field="name" [suggestions]="batchResults" (completeMethod)="searchBatch($event)">
      <ng-template let-brand pTemplate="item">
         <div>{{ brand.name }}</div>
         <small>{{ brand.batch_code }}</small>
      </ng-template>

   </p-autoComplete>
   <button *ngIf="batchName?.name" (click)="clearBatch()" class="btn btn-info">Clear</button>
</div>
<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">
   <ng-template let-item lt-cell-template field="viewed">
      <a class="btn-block btn" (click)="markAsRead(item)" *ngIf="['paid','partial'].indexOf(item.status) > -1">
         <i [ngbTooltip]="item.read_at? 'Mark as unread':'Mark as read' " class="table-vertical-align pi pi-eye"
            [ngClass]="{read:item.read_at , unread: item.read_at===null}"></i>
      </a>
   </ng-template>
   <ng-template field="payment_mode" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.payment_mode"></app-badge>
   </ng-template>
   <ng-template field="status" let-idx="index" lt-cell-template="" let-item="">
      <app-badge [text]="item.status"></app-badge>
   </ng-template>
   <ng-template field="user.name" let-idx="index" lt-cell-template="" let-item="">
      <div class="d-flex">
         <app-cdn-image [src]="item?.user?.application_detail?.profile_image" width="100" height="100"></app-cdn-image>
         <div class="pl-3">
            <div class="font-weight-bold">{{ item.user?.name }}</div>
            <small>{{ item.user?.email }}</small>
         </div>
      </div>
   </ng-template>
   <ng-template field="actions" lt-cell-template let-item>
      <div ngbDropdown class="d-inline-block">
         <button class="btn btn-sm btn-primary" id="dropdownBasic1" ngbDropdownToggle>Actions</button>
         <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button (click)="downloadApplication(item)" ngbDropdownItem>Download Application</button>
            <button (click)="viewOrderDetails(item)" ngbDropdownItem>View Details</button>
            <button (click)="deleteOrder(item)" ngbDropdownItem class="text-danger">Delete</button>
         </div>
      </div>

   </ng-template>
   <ng-template field="amount_paid" let-idx="index" lt-cell-template="" let-item="">
      <div class="font-weight-bold text-success">{{ item.amount_paid|currency:'INR' }}</div>
      <small class="d-block">of {{ item.payable_amount|currency:'INR' }}</small>
   </ng-template>
</xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{ form.value.id ? 'Update' : 'New' }} TestOrder</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form">

      </form>
   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{ form.value.id ? 'Update' : 'Save' }}</button>
   </div>
</ng-template>

<ng-template #actions let-item="item" let-data="data">


</ng-template>

<ng-template #details let-modal>
   <div class="modal-header">
      <h5 class="modal-title">#Test Order Id: {{ selectedItem.id }}</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <ul #nav="ngbNav" ngbNav class="nav-tabs">
         <li [ngbNavItem]="1">
            <a ngbNavLink>Order Details</a>
            <ng-template ngbNavContent>
               <table class="table table-bordered table-striped">
                  <tr class="font-weight-bold">
                     <td>Name</td>
                     <td>Price</td>
                     <td>Discount</td>
                     <td>Payable</td>
                     <td>Amount Paid</td>
                     <td>Penalty</td>
                     <td>Status</td>
                     <td>Last Payment Date</td>

                  </tr>
                  <tbody>
                  <tr *ngFor="let i of selectedItem.items">
                     <td>{{ i.product_name }}</td>
                     <td>{{ i.total }}</td>
                     <td>{{ i.discount }}</td>
                     <td>{{ i.payable }}</td>
                     <td>{{ i.amount_paid }}</td>
                     <td>{{ i.penalty }}</td>
                     <td><span class="tbl-status" [ngClass]="'status-'+i.status">{{ i.status }}</span></td>
                     <td>{{ i.expiry_date|date:'d-MMM-yyyy':'530' }}
                        <a [ngbPopover]="popContent" triggers="manual" #p1="ngbPopover"
                           popoverTitle="Extend Last Payment Date" [autoClose]="false" placement="left"
                           *ngIf="['expired','partial'].indexOf(i.status)> -1"
                           class="mt-1  ml-2 text-primary p-0" (click)="openExpiryUpdater(p1,i)">Extend</a>
                     </td>

                  </tr>
                  <tr>
                     <td class="font-weight-bold">Total</td>
                     <td>{{ selectedItem.order_total }}</td>
                     <td>{{ selectedItem.coupon_discount }}</td>
                     <td>{{ selectedItem.payable_amount }}</td>
                     <td>{{ selectedItem.amount_paid }}</td>
                  </tr>
                  </tbody>
               </table>
               <div *ngIf="selectedItem.coupon">Coupon Code : <strong>{{ selectedItem.coupon }}</strong></div>

            </ng-template>
         </li>
         <li [ngbNavItem]="2">
            <a ngbNavLink>User Details</a>
            <ng-template ngbNavContent>
               <form [formGroup]="userDetails">
                  <div class="row">
                     <div class="col-sm-6">
                        <div class="form-group">
                           <label for="">Name</label>
                           <input readonly class="form-control" type="text" formControlName="name">
                        </div>
                     </div>
                     <div class="col-sm-6">
                        <div class="form-group">
                           <label for="">Email</label>
                           <input readonly class="form-control" type="text" formControlName="email">
                        </div>
                     </div>
                     <div class="col-sm-6">
                        <div class="form-group">
                           <label for="">Mobile 1</label>
                           <input readonly class="form-control" type="text" formControlName="mobile1">
                        </div>
                     </div>
                     <div class="col-sm-6">
                        <div class="form-group">
                           <label for="">Mobile 2</label>
                           <input class="form-control" type="text" formControlName="mobile2">
                        </div>
                     </div>
                     <div class="col-sm-6">
                        <div class="form-group">
                           <label for="">DOB</label>
                           <input class="form-control" type="text" formControlName="dob">
                        </div>
                     </div>
                     <div class="col-sm-6">
                        <div class="form-group">
                           <label for="">Gender</label>
                           <select class="form-control" type="text" formControlName="gender">
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="third_gender">Third Gender</option>
                           </select>
                        </div>
                     </div>
                     <div class="col-sm-6">
                        <div class="form-group">
                           <label for="">Father Name</label>
                           <input class="form-control" type="text" formControlName="father_name">
                        </div>
                     </div>
                     <div class="col-sm-6">
                        <div class="form-group">
                           <label for="">Category</label>
                           <select class="form-control" type="text" formControlName="category">
                              <option *ngFor="let c of studentCategories" [value]="c.value">{{ c.label }}</option>
                           </select>
                        </div>
                     </div>
                     <div class="col-sm-6">
                        <div class="form-group">
                           <label class="d-block">Handicapped</label>
                           <app-toggle-switch formControlName="handicapped"></app-toggle-switch>
                        </div>
                     </div>
                     <div class="col-12">
                        <div class="section">Address</div>
                     </div>
                     <div class="col-sm-6">
                        <div class="form-group">
                           <label for="">Address</label>
                           <input class="form-control" type="text" formControlName="address">
                        </div>
                     </div>
                     <div class="col-sm-6">
                        <div class="form-group">
                           <label for="">City</label>
                           <input class="form-control" type="text" formControlName="city">
                        </div>
                     </div>
                     <div class="col-sm-6">
                        <div class="form-group">
                           <label for="">Pincode</label>
                           <input class="form-control" type="text" formControlName="pincode">
                        </div>
                     </div>
                     <div class="col-sm-6">
                        <div class="form-group">
                           <label for="">District</label>
                           <input class="form-control" type="text" formControlName="district">
                        </div>
                     </div>
                     <div class="col-sm-6">
                        <div class="form-group">
                           <label for="">State</label>
                           <input class="form-control" type="text" formControlName="state">
                        </div>
                     </div>
                     <div class="col-sm-12">
                        <div class="form-group">
                           <label for="">Profile Image</label>
                           <app-photo-uploader formControlName="profile_image"></app-photo-uploader>
                        </div>
                     </div>

                  </div>
                  <button (click)="updateApplication()" class="btn btn-primary" type="button">Update User Details
                  </button>
               </form>


            </ng-template>
         </li>
         <li [ngbNavItem]="3">
            <a ngbNavLink>Transactions</a>
            <ng-template ngbNavContent>


               <table class="table table-bordered table-striped">
                  <tr class="font-weight-bold">
                     <td>ID</td>
                     <td>Transaction Id</td>
                     <td>Date</td>
                     <td>Source</td>
                     <td>Status</td>
                     <td>Amount</td>
                     <td></td>
                  </tr>
                  <tbody>
                  <tr *ngFor="let t of selectedItem.transactions">
                     <td>{{ t.id }}</td>
                     <td>{{ t.gateway_transaction_id }}</td>
                     <td>{{ t.created_at|date:'d MMM yyyy':'530' }}</td>
                     <td>{{ t.bank_or_card_name }}</td>
                     <td>
                        <app-badge [text]="t.gateway_status"></app-badge>
                     </td>
                     <td>{{ t.amount }}</td>
                     <td>
                        <a (click)="addTransaction(t)" ngbTooltip="Edit" class="btn btn-sm"><i
                           class="fa fa-edit"></i></a>
                        <a (click)="deleteTransaction(t)" ngbTooltip="Delete" class="btn btn-sm text-danger"><i
                           class="fa fa-times"></i></a>
                     </td>
                  </tr>
                  </tbody>
               </table>
               <button type="button" (click)="addTransaction()" class="btn btn-block btn-success mt-2 mb-2">Add New
                  Transaction
               </button>
            </ng-template>
         </li>
      </ul>

      <div [ngbNavOutlet]="nav"></div>

   </div>

</ng-template>
<ng-template #popContent let-item="item" let-popover="popover">
   <app-date-picker #dp></app-date-picker>
   <div class="d-flex">
      <button (click)="popover.close()" class="btn mt-0 mr-1 btn-danger btn-sm btn-primary btn-block">Cancel</button>
      <button (click)="updateExpiryDate(item,dp.parsedValue,popover)" class="btn  mt-0   btn-sm btn-primary btn-block">
         Update
      </button>

   </div>
</ng-template>
